import React from "react";
import { useSelector } from "react-redux";
import CmsBlock from "app/layout/cmsBlock";
import Iframe from "react-iframe";
import Image from "core/components/image/image";
import {
  MDBBox,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBLink,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import HeroBanner from "../../heroBanner";
import Button from "core/components/button";
import themeConfiguration from "config/themeConfiguration";
import { frameArray as havenFrameArray } from "app/pages/Custom/haven/data";
import { frameArray as urbanFrameArray } from "app/pages/Custom/urban/data";
import { frameArray as seniorFrameArray } from "app/pages/Custom/senior/data";
// import { modelsArray as monogramFrameArray } from "app/pages/Custom/monogram/data";
import { styles as benchmadeRoundOttomansStylesArray } from "app/pages/Custom/benchmade-round-ottomans/data";
import { styles as benchmadeSquareOttomansStylesArray } from "app/pages/Custom/benchmade-square-ottomans/data";
import { styles as benchmadeBenchesStylesArray } from "app/pages/Custom/benchmade-benches/data";
import { tops as liveEdgeTops } from "app/pages/Custom/live-edge/data";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { pathnameIncludes } from "app/utils/utils";
import useMonogramHook from "./useMonogramHook";
import Price from "core/components/price/price";

const CollectionCustom = ({
  heroBannerProps,
  catImages,
  item,
  howItWorksId,
  catVideo,
  category,
  categoryMain,
  ...props
}) => {
  const { monogramData } = useMonogramHook();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  let locationArray = window.location.pathname.split("/");
  locationArray.pop();
  let prevLocation = locationArray.join("/");
  let prevLocationName =
    locationArray?.[locationArray.length - 1].replace(/-/g, " ") ||
    "collection";
  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  let sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "25%",
    slidesToShow: 1,
    slidesToScroll: 1,
    controls: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          // className: "",
          centerMode: false,
          infinite: true,
          centerPadding: "5px",
          slidesToShow: 1,
          slidesToScroll: 1,
          controls: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
          appendDots: (dots) => {
            return (
              <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
            );
          },
        },
      },
    ],
  };

  // start
  let stepsDescription = category?.steps_description;
  let parsedStepsDescription;
  const arr = [];
  if (stepsDescription) {
    parsedStepsDescription = JSON.parse(stepsDescription)[0];
    for (const [key, value] of Object.entries(parsedStepsDescription)) {
      arr.push({ name: key, number: value });
    }
  }
  // End_
  let downloadBrochure = category?.catalog_pdf;
  let downloadBrochure2 = category?.catalog_pdf_2;
  let downloadBrochureTitle = category?.catalog_pdf_title;
  let downloadBrochureTitle2 = category?.catalog_pdf_title2;

  const collectionSlug = category.name.replace(/\s/g, "-").toLowerCase();

  const frameArray = collectionSlug.includes("urban")
    ? urbanFrameArray
    : collectionSlug.includes("senior")
    ? seniorFrameArray
    : collectionSlug.includes("monogram")
    ? monogramData
    : collectionSlug.includes("haven")
    ? havenFrameArray
    : collectionSlug.includes("benchmade-round-ottomans")
    ? benchmadeRoundOttomansStylesArray
    : collectionSlug.includes("benchmade-ottomans")
    ? benchmadeSquareOttomansStylesArray
    : collectionSlug.includes("benchmade-benches")
    ? benchmadeBenchesStylesArray
    : [
        ...liveEdgeTops?.map((top) => ({
          ...top,
          name: `${top?.size} ${top?.finish}`,
          image: top?.modelImage,
        })),
      ];

  return (
    <MDBContainer fluid className="collection-wrapper collection-custom">
      <MDBRow className="img-full justify-content-center">
        <HeroBanner {...heroBannerProps} />
      </MDBRow>
      <MDBRow className="my-5">
        <MDBCol>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <MDBBox className="d-flex align-items-center">
                  <MDBIcon
                    icon="caret-left"
                    size="2x"
                    className="mr-3 text-default"
                  />
                  <MDBLink to={prevLocation}>
                    <MDBTypography
                      className="text-default"
                      tag="h2"
                      variant="display-5"
                    >
                      Back to {titleCase(prevLocationName)}
                    </MDBTypography>
                  </MDBLink>
                </MDBBox>
                <MDBBox className="my-5 border-light border-bottom"></MDBBox>
              </MDBCol>
            </MDBRow>
            {(!isMobile && (
              <>
                <MDBRow className="d-flex justify-content-between steps-description-wrapper">
                  {arr &&
                    arr.map((item, index) => {
                      return (
                        <MDBCol
                          md="2"
                          className="d-flex align-items-center"
                          key={index}
                        >
                          <MDBTypography
                            tag="h1"
                            style={{
                              color: "#6C808B",
                              marginRight: "2rem",
                              fontSize: "7rem",
                              fontWeight: "300",
                            }}
                          >
                            {item.number}
                          </MDBTypography>
                          <MDBTypography
                            tag="h1"
                            style={{
                              color: "#6C808B",
                              maxWidth: "7.6rem",
                              fontWeight: "300",
                            }}
                          >
                            {item.name}
                          </MDBTypography>
                        </MDBCol>
                      );
                    })}
                </MDBRow>
                <MDBRow className="d-flex justify-content-around mt-5">
                  {catImages?.map((item, index) => (
                    <MDBCol
                      key={item.image_url}
                      size="12"
                      lg="4"
                      className="py-4"
                    >
                      <Image
                        className="img-fluid w-100 collection-img"
                        source={item.image_url}
                      />
                    </MDBCol>
                  ))}
                </MDBRow>
              </>
            )) || (
              <>
                <MDBRow className="d-flex  mb-4 steps-description-wrapper">
                  <MDBCol className={`d-flex justify-content-center`}>
                    {arr &&
                      arr.map((item, index) => {
                        return (
                          <MDBBox
                            // size="2"
                            className="d-flex align-items-center justify-content-center step-item"
                            key={index}
                          >
                            <MDBTypography
                              tag="h1"
                              style={{
                                color: "#6C808B",
                              }}
                            >
                              {item.number}
                            </MDBTypography>
                            <MDBTypography
                              tag="h2"
                              style={{
                                color: "#6C808B",
                                fontWeight: "300",
                              }}
                            >
                              {item.name}
                            </MDBTypography>
                          </MDBBox>
                        );
                      })}
                  </MDBCol>
                </MDBRow>
                <Slider {...sliderSettings}>
                  {catImages.map((item, index) => (
                    <MDBCol
                      key={item.image_url}
                      size="12"
                      lg="4"
                      className="py-4"
                    >
                      <Image
                        className="img-fluid w-100 collection-img"
                        source={item.image_url}
                      />
                    </MDBCol>
                  ))}
                </Slider>
              </>
            )}
            {howItWorksId && (
              <>
                <MDBRow>
                  <MDBCol size="12" className="text-center mt-5 py-5">
                    <MDBTypography
                      className="text-body my-5"
                      tag="h2"
                      variant="display-1"
                    >
                      How it Works
                    </MDBTypography>
                    <MDBBox className="separator dark" />
                  </MDBCol>
                </MDBRow>
                <MDBRow className={`collection-how-works`}>
                  <CmsBlock id={howItWorksId} />
                </MDBRow>
              </>
            )}
            {catVideo === null ? (
              <></>
            ) : (
              <MDBRow className="mt-5">
                <MDBCol size="12" className="mt-5">
                  <div className="iFrame-wrapper">
                    <Iframe
                      url={catVideo}
                      frameborder="0"
                      allowfullscreen
                      allowtransparency
                    />
                  </div>
                </MDBCol>
                <MDBCol size="12" className="text-center mt-5">
                  <MDBLink to="#" className="default btn-default btn">
                    View Collection
                  </MDBLink>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
          <MDBContainer fluid className="bg-gray-lighten-4 py-5 my-2">
            <MDBContainer className="text-center">
              <MDBTypography
                className="text-body mb-3"
                tag="h2"
                variant="display-1"
              >
                Download
                {/* {category.has_configurator === 0
                  ? "Learn More"
                  : "Start Building"} */}
              </MDBTypography>
              <MDBTypography tag="h3" className="mb-4">
                {/* {category.has_configurator === 0
                  ? "Download the PDF below to more information about the collection"
                  : "Select one of our frames to start with or"} */}
              </MDBTypography>

              {downloadBrochure2 || downloadBrochure ? (
                <MDBBox
                  className={`${
                    downloadBrochure2 && downloadBrochure
                      ? "collection-buttons-pdf"
                      : "collection-buttons-pdf-single"
                  }`}
                >
                  {(downloadBrochure && (
                    <MDBBox>
                      <Button
                        className="btn-default btn btn-lg"
                        onClick={() => {
                          window.open(
                            themeConfiguration.magento_url +
                              themeConfiguration.media_url +
                              themeConfiguration.category_url +
                              "/" +
                              downloadBrochure,
                            "_blank"
                          );
                        }}
                      >
                        <MDBIcon far icon="file-pdf" className="mr-3" />
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            fontSize: "1.4rem",
                            color: "#FFFFFF",
                            fontWeight: "400",
                          }}
                        >
                          {downloadBrochureTitle}
                        </span>
                      </Button>
                    </MDBBox>
                  )) || <></>}
                  {(downloadBrochure2 && (
                    <MDBBox>
                      <Button
                        className="btn-default btn btn-lg"
                        onClick={() => {
                          window.open(
                            themeConfiguration.magento_url +
                              themeConfiguration.media_url +
                              themeConfiguration.category_url +
                              "/" +
                              downloadBrochure2,
                            "_blank"
                          );
                        }}
                      >
                        <MDBIcon far icon="file-pdf" className="mr-3" />
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            fontSize: "1.4rem",
                            color: "#FFFFFF",
                            fontWeight: "400",
                          }}
                        >
                          {downloadBrochureTitle2}
                        </span>
                      </Button>
                    </MDBBox>
                  )) || <></>}
                </MDBBox>
              ) : (
                <></>
              )}
              {category.has_configurator !== 0 && (
                <>
                  <MDBTypography tag="h3" className="mt-4">
                    or
                  </MDBTypography>
                  <MDBTypography
                    className="text-body mb-3 mt-3"
                    tag="h2"
                    variant="display-1"
                  >
                    Start Building
                  </MDBTypography>
                  <MDBTypography tag="h3" className="mb-4">
                    Select one of our frames to customize
                  </MDBTypography>
                </>
              )}
            </MDBContainer>
          </MDBContainer>
          {category.has_configurator === 0 && category?.frames?.length ? (
            <>
              <MDBContainer style={{ marginTop: "5rem", marginBottom: "8rem" }}>
                <MDBRow>
                  {category?.frames.map((frame, index) => {
                    return (
                      <MDBCol md="3" key={index}>
                        <MDBContainer
                          className="mb-5 mt-5 text-center collection-products-wrapper"
                          style={{ borderBottom: "3px solid #6C808B" }}
                        >
                          <img
                            className={`w-100`}
                            src={themeConfiguration.magento_url + frame.image}
                            alt=""
                          />
                          <MDBTypography tag="h4" className="mt-3 mb-5">
                            {frame.frame_id + " " + frame.name}
                          </MDBTypography>
                        </MDBContainer>
                      </MDBCol>
                    );
                  })}
                </MDBRow>
              </MDBContainer>
            </>
          ) : (
            <>
              <MDBContainer className="no-conf-wrapper">
                <MDBRow>
                  {frameArray.map((frame, index) => {
                    return (
                      <MDBCol xl="3" lg="4" md="6" size="6" key={index}>
                        <MDBContainer className="mb-4 mt-5 text-center d-flex justify-content-center p-0">
                          <MDBLink
                            className={`p-0`}
                            to={
                              collectionSlug.includes("monogram")
                                ? `/build-your-own/${collectionSlug}/${frame.sku}`
                                : frame.recliner
                                ? `/build-your-own/${collectionSlug}/S-XXX${frame.sku}-XX`
                                : collectionSlug.includes("benchmade-round")
                                ? `/build-your-own/${collectionSlug}/` +
                                  frame.id +
                                  "XXX-" +
                                  frame.size
                                : collectionSlug.includes("benchmade-benches")
                                ? `/build-your-own/${collectionSlug}/${frame.sku.replace(
                                    "-",
                                    ""
                                  )}X-XX`
                                : collectionSlug.includes("benchmade-square")
                                ? `/build-your-own/${collectionSlug}/${frame.sku}XXX-XX`
                                : collectionSlug.includes("live-edge")
                                ? `/build-your-own/${collectionSlug}/${frame?.sku}`
                                : `/build-your-own/${collectionSlug}/XXXX-${frame.sku}`
                            }
                            target="__blank"
                            rel="noreferrer"
                          >
                            <div
                              className={`frame-image-wrapper ${
                                pathnameIncludes("live-edge") &&
                                "live-edge-frame"
                              }`}
                            >
                              <img
                                className={
                                  collectionSlug.includes("monogram") &&
                                  "monogramImg"
                                }
                                src={frame.image}
                                alt={frame?.name}
                              />
                            </div>
                            <div
                              className="name-and-sku-wrapper w-100 pt-5 pb-4"
                              style={{ borderBottom: "3px solid #6C808B" }}
                            >
                              <MDBTypography tag="h3" className="mb-3 body-2 ">
                                {collectionSlug.includes("monogram")
                                  ? frame.sku
                                  : frame.recliner
                                  ? "S-__ __ __" + frame.sku + "-__ __"
                                  : collectionSlug.includes("benchmade-round")
                                  ? frame.id + " __ __ __ - " + frame.size
                                  : collectionSlug.includes("benchmade-benches")
                                  ? frame.sku?.replace("-", "") + "__ - __ __"
                                  : collectionSlug.includes("benchmade-square")
                                  ? frame.sku + "__ __ __ - __ __"
                                  : collectionSlug.includes("live-edge")
                                  ? frame.sku
                                  : "__ __ __ __" + "-" + frame.sku}
                              </MDBTypography>
                              <MDBTypography
                                tag="h4"
                                className="mb-3 display-5"
                                style={{
                                  fontWeight: "400",
                                  height: pathnameIncludes(
                                    "collections/build-your-own/live-edge"
                                  )
                                    ? "32px"
                                    : "auto",
                                }}
                              >
                                {frame.name}
                              </MDBTypography>
                              {pathnameIncludes("monogram") &&
                                frame?.price_range && (
                                  <MDBTypography
                                    tag="h5"
                                    className="body-1 w-100 fwregular text-center custom-price"
                                  >
                                    Starting at:{" "}
                                    <Price
                                      price={
                                        frame?.price_range?.minimum_price
                                          ?.final_price?.value
                                      }
                                      type={"fixed"}
                                    />
                                  </MDBTypography>
                                )}
                              {/*Price commented temporarily*/}
                              {/* {(isLoggedIn && frame.price && (
                                <MDBTypography
                                  tag="h5"
                                  className="body-1 w-100 fwregular text-center custom-price"
                                >
                                  Starting at: {frame.price}
                                </MDBTypography>
                              )) || <></>} */}
                            </div>
                            <Button
                              className={`btn-secondary btn  p-0 mt-5 mx-0 w-100`}
                            >
                              Customize
                            </Button>
                          </MDBLink>
                        </MDBContainer>
                      </MDBCol>
                    );
                  })}
                </MDBRow>
              </MDBContainer>
            </>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CollectionCustom;
