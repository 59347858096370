import Icon from "app/assets/icon/icon";
import CmsBlock from "app/layout/cmsBlock";
import Logo from "app/layout/logo";
import { ModalConsumer } from "core/components/modal/ModalContext";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBTypography,
} from "mdbreact";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import NewsLetterModal from "./newsletterModal/newsletterModal";
import apolloClient from "core/graphql/apolloClient";
import { clearSiteData } from "app/utils/utils";
import SocialIcons from "./socialIcons";

const Footer = memo((props) => {
  let { categoryFooter } = props;
  let childrenCategories = categoryFooter?.children?.[0]?.children?.length
    ? [...categoryFooter?.children?.[0]?.children]
    : [];
  let firstCategory = childrenCategories.shift();

  return (
    <MDBContainer fluid id="footer" className="bg-gray-darken-3 px-0">
      <MDBRow className="w-100 m-0">
        <MDBCol className="w-100 py-5 d-flex justify-content-center align-items-center">
          <Logo light />
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow className="my-2">
          <MDBCol>
            <MDBBox className="separator" />
          </MDBCol>
        </MDBRow>
        <MDBRow className="py-4 ">
          <MDBCol lg={"12"} className={"d-none footer-categories"}>
            <MDBListGroup>
              <MDBListGroupItem>
                <CmsBlock id="footer-links-one" />
                <MDBRow className="mt-auto">
                  <MDBCol className="d-flex justify-content-center">
                    <ModalConsumer value={{ useWrapper: true }}>
                      {({ showModal }) => (
                        <MDBBtn
                          onClick={(e) => {
                            showModal(() => {
                              return <NewsLetterModal {...props} />;
                            });
                          }}
                        >
                          Newsletter Sign up
                        </MDBBtn>
                      )}
                    </ModalConsumer>
                  </MDBCol>
                </MDBRow>
              </MDBListGroupItem>
            </MDBListGroup>
            <MDBListGroup>
              <MDBListGroupItem>
                <CmsBlock id="footer-links-two" />
                <CmsBlock id="footer-rep-portal" />
              </MDBListGroupItem>
            </MDBListGroup>
            <MDBListGroup>
              <MDBListGroupItem>
                <CmsBlock id="footer-links-three" />
                <CmsBlock id="footer-make-a-payment" />
              </MDBListGroupItem>
            </MDBListGroup>
            <MDBListGroup>
              <MDBListGroupItem>
                <CmsBlock id="footer-links-four" />
                <MDBRow className="mt-auto">
                  <MDBCol className="flex justify-content-center">
                    <MDBBtn className="d-block m-auto" onClick={clearSiteData}>
                      Update website version
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBListGroupItem>
            </MDBListGroup>
          </MDBCol>
          <MDBCol lg={"12"} className={"mt-4 footer-social-container"}>
            <MDBRow
              className={
                "d-flex justify-content-center align-items-center h-100"
              }
            >
              <SocialIcons />
              <a
                href={"https://jolausa.com/"}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Icon
                  icon="logo_footer_orange_white"
                  className={"jola-logo-lg"}
                />
              </a>
            </MDBRow>
          </MDBCol>
          <MDBCol size="8" lg={"0"} sm={"6"} className="mx-auto py-4 d-lg-none">
            <CmsBlock id="footer-updates-signup" />
            <MDBCol className="d-flex justify-content-center">
              <ModalConsumer value={{ useWrapper: true }}>
                {({ showModal }) => (
                  <MDBBtn
                    onClick={(e) => {
                      showModal(() => {
                        return <NewsLetterModal {...props} />;
                      });
                    }}
                  >
                    Sign up
                  </MDBBtn>
                )}
              </ModalConsumer>
            </MDBCol>
            <MDBContainer>
              <CmsBlock id="footer-social-links" />
              <SocialIcons />
            </MDBContainer>
            <CmsBlock id="footer-rep-portal" />
            <CmsBlock id="footer-make-a-payment" />
            <MDBRow>
              <MDBCol className="flex justify-content-center mt-4 mt-lg-0">
                <MDBBtn className="d-block m-auto" onClick={clearSiteData}>
                  Update website version
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <a
              href={"https://jolausa.com/"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <Icon icon="logo_footer_orange_white" className={"jola-logo"} />
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBBox className="separator" />
      <CmsBlock id="footer-bottom" />
    </MDBContainer>
  );
});

export default Footer;
