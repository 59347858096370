// @ts-check
import { SortEnum } from "app/generated/graphql";
import Config from "config/themeConfiguration";
import { fromPairs } from "lodash";
import qs from "qs";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CategoryQueryContainer from "./query-container";
import SkeletonCategory from "./skeleton-category";
import {
  filterSchema,
  getQueryFilter,
  initialFilter,
  initialSort,
  selectFabrics,
} from "./utils/utils";
import CollectionImgPlaceholder from "app/assets/images/collectionDetailImagePlaceholder.png";
import { getParamValue, pathnameIncludes } from "app/utils/utils";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const CATEGORY_QUERY = gql`
  query category($id: Int) {
    category(id: $id) {
      uid
      hero_image
      name
      description
    }
  }
`;

/**
 *
 * @type {React.FC<*>}
 */
const CategoryStateContainer = (props) => {
  const { setFinishCategory, categories, finishCategory } = props;
  let categoryIds = useMemo(() => {
    let arr = [{ value: "", label: "All" }];
    if (!categories?.length) return [];
    // @ts-ignore
    categories.forEach((cat) =>
      arr.push({
        value: cat?.id,
        label: cat?.name,
      })
    );
    return arr;
  }, [categories]);

  const fabrics = useSelector(selectFabrics);
  const inFabricsContext = React.useMemo(() => {
    return fabrics?.children.find(
      (
        /** @type {{ id: number, children: Array<{ id: number }> }} */ category
      ) =>
        category.id === props.category?.id ||
        category.children?.find((child) => child.id === props.category?.id)
    );
  }, [fabrics, props.category]);
  // @ts-ignore
  const [url, setUrl] = useState(props.url);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isInStock = useMemo(() => {
    return props?.category?.name === "InStock";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.category?.name]);

  const categoryId = props.category?.id;
  const { data: categoryData } = useQuery(CATEGORY_QUERY, {
    variables: {
      id: categoryId,
    },
    fetchPolicy: "no-cache",
    skip: !categoryId,
  });

  const { category } = categoryData || {};

  let heroImage = category?.hero_image || props?.category?.hero_image;

  const [scrollBySkuDone, setScrollBySkuDone] = useState(false);
  let magentoUrl = Config.magento_url;
  let mediaUrl = Config.media_url;
  let categoryUrl = Config.category_url;
  const queryParams = new URLSearchParams(window.location.search);
  const [searchSku] = useState(queryParams.get("sku"));
  const [initLaneLoaded, setInitLaneLoaded] = useState(false);
  const [loadingData, setloadingData] = useState(false);

  const page = queryParams.get("page") ?? 1;
  const pageSizeUrl = queryParams.get("pageSize") ?? 96;
  const searchPageInput = queryParams.get("search");
  const optionsVal = queryParams.get("options");
  const categoryName = queryParams.get("category");

  const categoryTypeId = queryParams.get("category_id_product");
  // const exactParam = queryParams.get("exact");
  const [searchVal, setSearchVal] = useState(
    searchPageInput && props.isSearchPage ? searchPageInput : ""
  );

  const [isExact, setExact] = useState(props.isSearchPage);

  let heroImageUrl =
    heroImage === null
      ? CollectionImgPlaceholder
      : `${magentoUrl}${
          heroImage?.includes("/media/catalog/category")
            ? heroImage
            : `${mediaUrl}${categoryUrl}/${heroImage}`
        }`;

  let [pageSize, setPageSize] = useState(
    pageSizeUrl ? pageSizeUrl : Config.pageSizeOptions[0].value
  );

  let [currentPage, setCurrentPage] = useState(
    window.location.href.includes("?page") ? page : 1
  );

  const [searchParamsLoaded, setSearchParamsLoaded] = useState(false);

  let searchParams = React.useMemo(
    () => qs.parse(window.location.search?.replace("?", "")),
    []
  );
  // let [showCollectionAsCategory, setShowCollectionAsCategory] = useState(false); // For not custom collection, to display collection as category
  let lastLevelName = props.url?.split("/")?.pop();
  lastLevelName =
    lastLevelName?.charAt(0)?.toUpperCase() +
    lastLevelName?.slice(1)?.toLowerCase();
  const heroBannerProps = {
    heroImage: heroImageUrl,
    name: category?.name || props?.category?.name || lastLevelName || "",
    description: category?.description || props?.category?.description || "",
  };

  const queryProps = {
    pageSize,
    currentPage,
  };

  /**
   *
   * @param {{ value: string, name: string }} param
   */
  function updateParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(param.name, param.value);
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  }

  // useEffect(() => {
  //   let exact = { name: "exact", value: isExact ? "1" : "0" };
  //   updateParam(exact);
  // }, [isExact]);

  useEffect(() => {
    // if (pathnameIncludes("search-results")) {
    let search = { name: "search", value: searchVal };

    updateParam(search);
    // }
  }, [searchVal]);

  /**
   *
   * @param {{ value: number }} pageSize
   */
  const handleSetPageSize = (pageSize) => {
    setCurrentPage(1);
    setPageSize(pageSize.value);
  };
  const toolbarProps = {
    pageSize,
    setPageSize: handleSetPageSize,
    currentPage,
    setCurrentPage,
    pageSizeOptions: Config.pageSizeOptions,
  };

  const location = useLocation();
  useEffect(() => {
    setCurrentPage(pageSize ? page : 1);
    setPageSize(pageSizeUrl ? pageSizeUrl : 96);
  }, [location]);
  /**
   *
   * @param {string} name
   * @returns {"" | "ffi_laners" | "ffi_lanect" | "ffi_lanesl"}
   */
  const getLaneFieldByName = (name) => {
    /**
     * @type {"" | "ffi_laners" | "ffi_lanect" | "ffi_lanesl"}
     */
    let laneField = "";
    switch (name) {
      case "Hospitality Contract":
        laneField = "ffi_lanect";
        break;
      case "Retail":
        laneField = "ffi_laners";
        break;
      case "Senior Living":
        laneField = "ffi_lanesl";
        break;
      default:
        laneField = "";
        break;
    }
    return laneField;
  };

  /**
   * @type {import('./utils/utils').IFilter}
   */
  // @ts-ignore
  const initialFilterState = React.useMemo(() => {
    /**
     * @type {import('./utils/utils').FilterParams}
     */
    const filterParams = fromPairs(
      Object.entries(qs.parse(location.search)).filter(([key, value]) => {
        /**
         * @type {import('yup').MixedSchema}
         */
        // @ts-ignore
        const current = filterSchema[key];
        if (current) {
          try {
            return !!current.validateSync(value);
          } catch {
            return false;
          }
        } else {
          return false;
        }
      })
    );
    // const price = filterParams.price?.split(",");
    const filterParams1 = qs.parse(queryParams.toString());
    const ffifabrics_avail_filter_bucket =
      filterParams1.ffifabrics_avail_filter_bucket;
    const ffi_qty_filter_bucket = !!filterParams1.ready_to_ship_filter
      ? filterParams1.ffi_qty_filter_bucket
      : null;
    const laneSearchVal = queryParams.get("options");
    const laneField = getLaneFieldByName(laneSearchVal ?? "");
    return {
      category_id_product: filterParams1.category_id_product ?? "",
      lane:
        filterParams.lane ??
        filterParams1.lane ??
        laneField ??
        initialFilter.lane,
      is_new: filterParams1.is_new === "true",
      in_stock_filter:
        filterParams1.in_stock_filter === "true"
          ? true
          : initialFilter?.in_stock_filter,
      ffi_assem_req:
        (filterParams?.ffi_assem_req &&
          JSON.parse(filterParams?.ffi_assem_req)) ??
        initialFilter?.ffi_assem_req,
      ffi_leatheravl:
        (filterParams?.ffi_leatheravl &&
          JSON.parse(filterParams?.ffi_leatheravl)) ??
        initialFilter?.ffi_leatheravl,
      ffi_collection: Array.isArray(filterParams1?.ffi_collection)
        ? filterParams1?.ffi_collection
        : Array.isArray(filterParams?.ffi_collection)
        ? filterParams?.ffi_collection
        : filterParams?.ffi_collection?.split(",") ??
          initialFilter.ffi_collection,
      ffifabrics_collection: Array.isArray(filterParams1?.ffifabrics_collection)
        ? filterParams1?.ffifabrics_collection
        : Array.isArray(filterParams?.ffifabrics_collection)
        ? filterParams?.ffifabrics_collection
        : filterParams?.ffifabrics_collection?.split(",") ??
          initialFilter.ffifabrics_collection,
      fficustopt_type_filterable:
        // filterParams?.fficustopt_type_filterable?.split(",") ??
        Array.isArray(filterParams?.fficustopt_type_filterable)
          ? filterParams?.fficustopt_type_filterable
          : filterParams1.fficustopt_type_filterable?.toString().split(",") ??
            initialFilter.fficustopt_type_filterable,
      ffifabrics_grade: Array.isArray(filterParams?.ffifabrics_grade)
        ? filterParams?.ffifabrics_grade
        : filterParams?.ffifabrics_grade?.split(",") ??
          initialFilter?.ffifabrics_grade,
      ffifabrics_category1: Array.isArray(filterParams1?.ffifabrics_category1)
        ? filterParams1?.ffifabrics_category1
        : Array.isArray(filterParams?.ffifabrics_category1)
        ? filterParams?.ffifabrics_category1
        : filterParams?.ffifabrics_category1?.split(",") ??
          initialFilter.ffifabrics_category1, //Replaced ffifabrics_pattern because it had wrong values
      ffifabrics_hexclrgrp: Array.isArray(filterParams?.ffifabrics_hexclrgrp)
        ? filterParams?.ffifabrics_hexclrgrp
        : filterParams?.ffifabrics_hexclrgrp?.split(",") ??
          initialFilter.ffifabrics_hexclrgrp,
      ffifabrics_finishtype: Array.isArray(filterParams?.ffifabrics_finishtype)
        ? filterParams?.ffifabrics_finishtype
        : filterParams?.ffifabrics_finishtype?.split(",") ??
          initialFilter.ffifabrics_finishtype,
      price:
        // (price && { from: Number(price[0]), to: Number(price[1]) }) ??
        filterParams1.price
          ? {
              // @ts-ignore
              from: Number(filterParams1.price?.from),
              // @ts-ignore
              to: Number(filterParams1.price?.to),
            }
          : initialFilter.price,
      ffifabrics_avail_filter_bucket:
        (ffifabrics_avail_filter_bucket && {
          // @ts-ignore
          from: Number(ffifabrics_avail_filter_bucket.from),
          // @ts-ignore
          to: Number(ffifabrics_avail_filter_bucket.to),
        }) ??
        initialFilter.ffifabrics_avail_filter_bucket,
      ffi_qty_filter_bucket:
        (ffi_qty_filter_bucket && {
          // @ts-ignore
          from: Number(ffi_qty_filter_bucket.from),
          // @ts-ignore
          to: Number(ffi_qty_filter_bucket.to),
        }) ??
        initialFilter.ffi_qty_filter_bucket,
      ready_to_ship_filter: !!filterParams1.ready_to_ship_filter,
    };
  }, [props.category]); // eslint-disable-line react-hooks/exhaustive-deps
  // * it's preferable to rely on location.search state instead of local state
  // * however using history.push({ search }) resets the scroll position (if you find a workaround, please refactor)
  const [filter, setFilter] = React.useState(initialFilterState);
  // * in case the user performs a reload, the params are going to persist

  React.useEffect(() => {
    const params = Object.entries(filter).filter(([, value]) =>
      Array.isArray(value) ? !!value.length : !!value
    );
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("categoryId"))
      params.push(["categoryId", urlParams.get("categoryId")]);

    params.push(["sortKey", sort.key], ["sortDirection", sort.direction]);
    if (props.isSearchPage) {
      params.push(["search", searchVal]);
      params.push([
        "category_id_product",
        categoryTypeId || searchParams?.category_id_product || "",
      ]);
      params.push(["category", categoryName]);
      params.push(["options", optionsVal ? optionsVal : "All"]);
    }

    if (params.length) {
      window.history.replaceState(
        null,
        "",
        window.location.origin
          .concat(window.location.pathname)
          .concat(
            params.length ? "?".concat(qs.stringify(fromPairs(params))) : ""
          )
      );
    }
    return () => {
      if (params.length) {
        let params = [
          { name: "pageSize", value: toolbarProps?.pageSize ?? 96 },
          {
            name: "page",
            value: toolbarProps?.currentPage ?? 1,
          },
          { name: "sortKey", value: sort.key },
          { name: "sortDirection", value: sort.direction },
        ];
        window.history.replaceState(null, "", addParamsToSearchString(params));
      }
    };
  }, [filter]);

  useEffect(() => {
    if (window.scrollY > 0 && !searchParamsLoaded) {
      setSearchParamsLoaded(true);
      for (const key in searchParams) {
        if (
          key === "page" ||
          key === "pageSize" ||
          ((key === "sortKey" || key === "sortDirection") &&
            window.location.search.includes("sortKey"))
        )
          return;

        setFilter((current) => ({
          ...current,
          [key]:
            searchParams[key] === "true"
              ? true
              : typeof searchParams[key] === "object" ||
                typeof searchParams[key] === "string"
              ? searchParams[key]
              : false,
        }));
      }
    }
  }, [searchParams, searchParamsLoaded]);

  useEffect(() => {
    if (loadingData)
      setTimeout(() => {
        // @ts-ignore
        let element = document.getElementById(searchSku);
        if (element && !scrollBySkuDone) {
          setScrollBySkuDone(true);
          window.scrollTo({
            behavior: "smooth",
            // @ts-ignore
            top: element.offsetTop,
          });
        }
      }, 500);
  }, [loadingData]);

  React.useEffect(() => {
    let params = [
      { name: "pageSize", value: toolbarProps?.pageSize },
      { name: "page", value: toolbarProps?.currentPage },
    ];
    window.history.replaceState(null, "", addParamsToSearchString(params));
  }, [toolbarProps]);

  /**
   * @param {any[]} params
   */
  function addParamsToSearchString(params, keepExisting = true) {
    const urlParams = new URLSearchParams(
      keepExisting ? window.location.search : ""
    );
    params.forEach((param) => {
      urlParams.set(param.name, param.value);
    });
    return `${window.location.pathname}?${urlParams.toString()}`;
  }

  function clearFilterParams() {
    let params = [
      {
        name: "pageSize",
        value: toolbarProps?.pageSize ?? 96,
      },
      {
        name: "page",
        value: toolbarProps?.currentPage ?? 1,
      },
      { name: "sortKey", value: sort.key },
      {
        name: "sortDirection",
        value: sort.direction,
      },
    ];
    window.history.replaceState(
      null,
      "",
      addParamsToSearchString(params, false)
    );
  }

  // React.useEffect(() => {
  //   //when the url is chagned return defult collection view
  //   setShowCollectionAsCategory(false);
  // }, [
  //   location.pathname.split("/")[location.pathname.split("/").length - 1],
  //   location.pathname.split("/")[location.pathname.split("/").length - 2],
  // ]);
  const setCollectionFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffi_collection']} */ ffi_collection
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_collection,
    }));
  }, []);

  const setCustomTypeFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['fficustopt_type_filterable']} */ fficustopt_type_filterable
  ) => {
    window.history.replaceState(
      null,
      "",
      addParamsToSearchString([
        {
          name: "fficustopt_type_filterable",
          value: fficustopt_type_filterable,
        },
      ])
    );
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      fficustopt_type_filterable,
    }));
  }, []);

  const setCategoryFilter = React.useCallback((val) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, category_id_product: val?.value }));
  }, []);

  const toggleIsNewFilter = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, is_new: !current.is_new }));
  }, []);
  const toggleInStockFilter = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      in_stock_filter: !current.in_stock_filter,
    }));
  }, []);
  const toogleReadyToShip = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ready_to_ship_filter: !current.ready_to_ship_filter,
    }));
  }, []);
  const toggleAssemblyFilter = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_assem_req: !current.ffi_assem_req,
    }));
  }, []);
  const toggleLeatherAvailableFilter = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_leatheravl: !current.ffi_leatheravl,
    }));
  }, []);
  const setLaneFilter = React.useCallback(
    (/** @type {import('./utils/utils').IFilter['lane']} */ lane) => {
      if (filter?.lane?.length !== lane?.length) setCurrentPage(1);
      setInitLaneLoaded(true);
      setFilter((current) => ({ ...current, lane }));
    },
    [filter?.lane]
  );
  const clearLaneFilter = React.useCallback(() => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      lane: initialFilterState.lane,
    }));
  }, [initialFilterState]);
  const clearFilter = React.useCallback(() => {
    if (!pathnameIncludes("search-results")) clearFilterParams();
    setFilter(initialFilter);
  }, [initialFilterState]);
  const setFabricsCollectionFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffifabrics_collection']} */ ffifabrics_collection
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, ffifabrics_collection }));
  }, []);
  const setGradeFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffifabrics_grade']} */ ffifabrics_grade
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, ffifabrics_grade }));
  }, []);
  const setPatternFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffifabrics_category1']} */ ffifabrics_category1
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, ffifabrics_category1 }));
  }, []);
  const setHexclrgrpFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffifabrics_hexclrgrp']} */ ffifabrics_hexclrgrp
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, ffifabrics_hexclrgrp }));
  }, []);

  const setFabricsFinishTypeFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffifabrics_finishtype']} */ ffifabrics_finishtype
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({ ...current, ffifabrics_finishtype }));
  }, []);

  const setPriceFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['price']} */ price
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      price,
    }));
  }, []);
  const setSquareFeetFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['price']} */ ffifabrics_avail_filter_bucket
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffifabrics_avail_filter_bucket,
    }));
  }, []);
  const setQuantityFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffi_qty_filter_bucket']} */ ffi_qty_filter_bucket
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_qty_filter_bucket,
    }));
  }, []);
  const setWidthFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffi_width_filter_bucket']} */ ffi_width_filter_bucket
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_width_filter_bucket,
    }));
  }, []);
  const setHeightFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffi_height_filter_bucket']} */ ffi_height_filter_bucket
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_height_filter_bucket,
    }));
  }, []);
  const setDepthFilter = React.useCallback((
    /** @type {import('./utils/utils').IFilter['ffi_depth_filter_bucket']} */ ffi_depth_filter_bucket
  ) => {
    setCurrentPage(1);
    setFilter((current) => ({
      ...current,
      ffi_depth_filter_bucket,
    }));
  }, []);
  /**
   * Returns Sort by url params, if it does not exist returns initial sort
   * @returns {import('./utils/utils').Sort}
   */
  const getInitialSort = () => {
    let key = getParamValue("sortKey", null);
    let direction = getParamValue("sortDirection", null);
    /** @type {import('./utils/utils').Sort} */
    let urlSort = initialSort;
    /**
     * @type {boolean}
     */
    let urlSortExists = false;
    if (
      (key === "name" ||
        key === "price" ||
        key === "ffi_introdat" ||
        key === "sku") &&
      (direction === SortEnum.Asc || direction === SortEnum.Desc)
    ) {
      urlSortExists = true;
      urlSort = { key, direction };
    }
    return urlSortExists
      ? urlSort
      : props.isSearchPage
      ? { key: "relevance", direction: SortEnum.Desc }
      : initialSort;
  };
  const [sort, setSort] = React.useState(getInitialSort());
  const toggleIsAsc = React.useCallback(() => {
    setSort((current) => ({
      ...current,
      direction:
        current.direction === SortEnum.Asc ? SortEnum.Desc : SortEnum.Asc,
    }));
  }, [setSort]);
  const listingProps = { items: [] };

  let finalFilters = {
    ...getQueryFilter(props.category?.id || props.mainCategory?.id, filter),
  };
  Object.keys(finalFilters).map((key) => {
    if (key.includes(",")) {
      let ffi = key.split(",");
      ffi.forEach((el) => {
        // @ts-ignore
        finalFilters[el] = { eq: "1" };
      });
    }
  });
  for (let i in finalFilters) {
    if (i.includes(",") || i === "") {
      // @ts-ignore
      delete finalFilters[i];
    }
  }

  const displayProps = {
    heroBannerProps,
    queryProps: {
      ...queryProps,
      filter:
        props.category?.id || props.mainCategory?.id || props?.id
          ? finalFilters
          : {},
      sort: { [sort.key]: sort.direction },
    },
    toolbarProps,
    listingProps,
  };

  /**
   * Update url params on sort change
   */
  React.useEffect(() => {
    let params = [
      { name: "sortKey", value: sort.key },
      { name: "sortDirection", value: sort.direction },
    ];
    window.history.replaceState(null, "", addParamsToSearchString(params));
  }, [sort]);

  const isFabricOrFinishesCategory = props.category?.url_path.includes(
    "fabrics-finishes"
  );

  useEffect(() => {
    if (props.url !== url) {
      clearFilter();
    }
  }, [clearFilter, props.url, url]);

  const isFinish = isFabricOrFinishesCategory
    ? props.category?.url_path.includes("/finishes/")
    : false;

  // eslint-disable-next-line no-lone-blocks

  {
    return props.category?.id || categoryIds?.length || props.id ? (
      <CategoryQueryContainer
        {...displayProps}
        categoryIds={categoryIds}
        categoryId={props.category?.id || props.id}
        setloadingData={setloadingData}
        inFabricsContext={inFabricsContext}
        filter={filter}
        setFabricsCollectionFilter={setFabricsCollectionFilter}
        setGradeFilter={setGradeFilter}
        setCustomTypeFilter={setCustomTypeFilter}
        setPatternFilter={setPatternFilter}
        setHexclrgrpFilter={setHexclrgrpFilter}
        setFabricsFinishTypeFilter={setFabricsFinishTypeFilter}
        setPriceFilter={setPriceFilter}
        setCollectionFilter={setCollectionFilter}
        toggleIsNewFilter={toggleIsNewFilter}
        setLaneFilter={setLaneFilter}
        clearLaneFilter={clearLaneFilter}
        clearFilter={clearFilter}
        sort={sort}
        setSort={setSort}
        toggleIsAsc={toggleIsAsc}
        isFabricOrFinishesCategory={isFabricOrFinishesCategory}
        isFinish={isFinish}
        toggleInStockFilter={toggleInStockFilter}
        toggleAssemblyFilter={toggleAssemblyFilter}
        toggleLeatherAvailableFilter={toggleLeatherAvailableFilter}
        setCategoryFilter={setCategoryFilter}
        isSearchPage={props.isSearchPage}
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        categoryName={categoryName}
        optionsVal={optionsVal}
        setSquareFeetFilter={setSquareFeetFilter}
        isExact={isExact}
        setExact={setExact}
        searchParams={searchParams}
        isInStock={isInStock}
        toogleReadyToShip={toogleReadyToShip}
        setQuantityFilter={setQuantityFilter}
        setWidthFilter={setWidthFilter}
        setHeightFilter={setHeightFilter}
        setDepthFilter={setDepthFilter}
        setCurrentPage={setCurrentPage}
        setFinishCategory={setFinishCategory}
        finishCategory={finishCategory}

        // showCollectionAsCategory={showCollectionAsCategory}
        // setShowCollectionAsCategory={setShowCollectionAsCategory}
      />
    ) : (
      <>
        <SkeletonCategory />
      </>
    );
  }
};

export default CategoryStateContainer;
