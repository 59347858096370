import Cart from "app/pages/Cart";
import { cartPaths } from "app/pages/Cart/config";
import Wishlists from "app/pages/collections";
import { Haven } from "app/pages/Custom/haven";
import { Urban } from "app/pages/Custom/urban";
import { Senior } from "app/pages/Custom/senior";
import { Monogram } from "app/pages/Custom/monogram";
import Login from "app/pages/customer/login";
import Register from "app/pages/customer/register";
import ThankYou from "app/pages/customer/register/ThankYou";
import { customerPaths } from "app/pages/customer/routes";
import ForgotPassword from "app/pages/forgotPassword";
import { Gallery } from "app/pages/Gallery";
import GalleryDetail from "app/pages/Gallery/galleryDetail";
import Homepage from "app/pages/home";
import VirtualShowroom from "app/pages/home/showroom/virtualShowroom";
import RepLocator from "app/pages/repLocator";
import Resources from "app/pages/Resources";
import { resourcesPaths } from "app/pages/Resources/config";
import SearchResultsPage from "app/pages/searchResultsPage";
import AccessibillityStatement from "app/pages/static/accessibillity-statement";
import Careers from "app/pages/static/careers";
import CompanyStory from "app/pages/static/company-story";
import Contact from "app/pages/static/contact";
import Craftsmanship from "app/pages/static/craftsmanship";
import EnvironmentalPolicy from "app/pages/static/environmental-policy";
import PrivacyPolicy from "app/pages/static/privacy-policy";
import TermsAndConditions from "app/pages/static/terms-and-conditions";
import Warranty from "app/pages/static/warranty";
import StoreLocator from "app/pages/storeLocator";
import Typography from "app/pages/typography";
import Account from "../../../pages/customer/account";
import ContactThankYou from "app/layout/ContactThankYou/ContactThankYou";
import Product from "app/pages/product";
import Sitemap from "app/pages/siteMap";
import FAQ from "app/pages/frequentlyAskedQuestions";
import SiteUpdates from "app/pages/SiteUpdates";
import VirtualDesignChallenge from "app/pages/VirtualDesignChallenge";
import Residential from "app/pages/Residential";
import Commercial from "app/pages/Commercial";

export default {
  home: { component: Homepage, exact: false },
  [customerPaths.children.register.path.slice(1)]: {
    component: Register,
    exact: true,
  },
  [customerPaths.children.register.children.thankYou.path.slice(1)]: {
    component: ThankYou,
    exact: true,
  },
  [customerPaths.children.account.path.slice(1)]: {
    component: Account,
    exact: false,
  },
  [customerPaths.children.collections.path.slice(1)]: {
    component: Wishlists,
    exact: false,
  },
  [cartPaths.path.slice(1)]: {
    component: Cart,
    exact: false,
  },
  login: { component: Login, exact: false },
  typography: { component: Typography, exact: false },
  "forgot-password": { component: ForgotPassword, exact: false },
  "create-password": { component: ForgotPassword, exact: false },
  "where-to-buy": { component: StoreLocator, exact: true },
  "sales-representative": { component: RepLocator, exact: true },
  inspirations: { component: Gallery, exact: true },
  vdc: {
    component: VirtualDesignChallenge,
    exact: true,
  },
  "inspirations/": { component: GalleryDetail, exact: false },
  "privacy-policy": { component: PrivacyPolicy, exact: false },
  faq: { component: FAQ, exact: false },
  announcements: { component: SiteUpdates, exact: false },
  "terms-and-conditions": { component: TermsAndConditions, exact: false },
  warranty: { component: Warranty, exact: false },
  "company-story": { component: CompanyStory, exact: false },
  "accessibillity-statement": {
    component: AccessibillityStatement,
    exact: false,
  },
  "environmental-policy": { component: EnvironmentalPolicy, exact: false },
  "build-your-own/haven-reserve": {
    component: Product,
    type: "Haven",
    isCustom: true,
    exact: false,
  },
  "build-your-own/urban-living": {
    component: Product,
    type: "Urban",
    isCustom: true,
    exact: false,
  },
  "build-your-own/senior-living-solutions": {
    component: Product,
    type: "Senior",
    isCustom: true,
    exact: false,
  },
  "build-your-own/monogram": {
    component: Product,
    type: "Monogram",
    isCustom: true,
    exact: false,
  },
  "build-your-own/benchmade-benches": {
    component: Product,
    type: "BenchmadeBenches",
    isCustom: true,
    exact: false,
  },
  "build-your-own/benchmade-round-ottomans": {
    component: Product,
    type: "BenchmadeRoundOttomans",
    isCustom: true,
    exact: false,
  },
  "build-your-own/benchmade-ottomans": {
    component: Product,
    type: "BenchmadeSquareOttomans",
    isCustom: true,
    exact: false,
  },
  "build-your-own/live-edge": {
    component: Product,
    type: "LiveEdge",
    isCustom: true,
    exact: false,
  },
  craftsmanship: { component: Craftsmanship, exact: false },
  careers: { component: Careers, exact: false },
  contact: { component: Contact, exact: false },
  [resourcesPaths.path.slice(1)]: { component: Resources, exact: false },
  "search-results": { component: SearchResultsPage, exact: false },
  "virtual-showroom": { component: VirtualShowroom, exact: false },
  "contact-thank-you": { component: ContactThankYou, exact: false },
  "sitemap.xml": { component: Sitemap, exact: false },
  residential: { component: Residential, exact: true },
  commercial: { component: Commercial, exact: true },
};

export { default as ErrorPage } from "app/pages/error";
export { default as TemporaryPage } from "app/pages/temporary";

export const DefaultPage = Homepage;
