import queryLoader from "app/graphql/queryLoader";
import { transform } from "core/components/cmsBlock/Parser";
import GraphQlWrapper from "core/components/graphQlWrapper";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "react-loading-skeleton";
import cmsBlocks from "generation/cmsBlocks.json";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const CMS_BLOCK = gql`
  query cmsBlocks($identifiers: [String]) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

const CmsDataSection = ({ data, skeleton = null, skeletonProps = null }) => {
  const { data: cmsBlockData } = useQuery(CMS_BLOCK, {
    fetchPolicy: "no-cache",
    variables: { identifiers: [data?.cmsBlocks?.items?.[0]?.identifier] },
  });
  if (cmsBlockData?.cmsBlocks?.items?.length) {
    return cmsBlockData.cmsBlocks.items.map((item) => (
      <React.Fragment key={`${item.identifier}`}>
        {ReactHtmlParser(
          item.content
            ?.replaceAll("&lt;", "<")
            ?.replaceAll("&gt;", ">")
            ?.replaceAll("&amp;nbsp;", "<br>"),
          {
            transform: (node, index) => transform(node, index, true),
          }
        )}
      </React.Fragment>
    ));
  } else {
    return <></>;
  }

  if (skeleton) {
    let SkeletonComp = skeleton;
    return <SkeletonComp {...skeletonProps} />;
  }
  return <Skeleton width={"100%"} height={"100%"} {...skeletonProps} />;
};

const QueryContainer = ({ id, skeleton = null, skeletonProps = null }) => {
  if (!id) {
    return (
      <Skeleton duration={3000} width={`100%`} className={`${id} skeleton`} />
    );
  }
  if (cmsBlocks.default?.[id]) {
    return (
      <CmsDataSection
        skeleton={skeleton}
        skeletonProps={skeletonProps}
        data={{ cmsBlocks: { items: [cmsBlocks.default?.[id]] } }}
      />
    );
  }
  let query = queryLoader("cmsBlock");
  return (
    <GraphQlWrapper query={query} saveQuery={true} variables={{ id: id }}>
      <CmsDataSection skeleton={skeleton} skeletonProps={skeletonProps} />
    </GraphQlWrapper>
  );
};

export default QueryContainer;
