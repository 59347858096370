import React from "react";
import HeroBanner from "./HeroBanner";
import Shop from "./Shop";
import CmsBlock from "app/layout/cmsBlock";

const HomeContainerV2 = () => {
  return (
    <>
      {/* <HeroBanner /> */}
      <CmsBlock id="crypton_hero_banner" />
      <Shop />
      <CmsBlock id="homepage_v2_multi-block" />
      <CmsBlock id="homepage_v2_i-am-fairfield" />
      <CmsBlock id="homepage_v2_lookbook" />
      <CmsBlock id="homepage_v2_libby-langdon" />
      <CmsBlock id="homepage_v2_to_the_trade" />
    </>
  );
};

export default HomeContainerV2;
