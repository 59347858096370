/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from "app/assets/icon/icon";
import { MDBBox, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import React, { useEffect, useState, useMemo } from "react";
import { Vector3 } from "three";
import { Player as HavenPlayer, zoomOptions } from "./HavenPlayer";
import { Player as UrbanPlayer } from "./UrbanPlayer";
import { Player as BenchmadeBenchesPlayer } from "./BenchmadeBenchesPlayer";
import { Player as BenchmadeRoundOttomansPlayer } from "./BenchmadeRoundOttomansPlayer";
import { Player as BenchmadeSquareOttomansPlayer } from "./BenchmadeSquareOttomansPlayer";
import { Player as SeniorPlayer } from "./SeniorPlayer";
import { Player as MonogramPlayer } from "./MonogramPlayer";
import { Player as LiveEdgePlayer } from "./LiveEdgePlayer";
import { useMediaQuery } from "react-responsive";
import Button from "core/components/button";
import Loader from "app/layout/loader";
import ARModal from "./arModal";
import themeConfiguration from "config/themeConfiguration";

const _Player = (
  {
    player,
    setPlayer,
    playerHeight = "100%",
    playerWidth = "100%",
    frame,
    arm,
    base,
    back,
    floor,
    striping,
    finish,
    powerOption,
    floorOptions,
    setFloor,
    // sectionals,
    mainFabric,
    insideBack,
    backFabric,
    weltFabric,
    cushionFabric,
    pillowWeltFabric,
    throwPillowFabric,
    isFullscreen,
    toggleFullscreen,
    nailSize,
    nailFinish,
    sku,
    seat,
    seatAndBack,
    accessoryLeft,
    accessoryRight,
    collectionPath,
    selectedBaseFinish,
    checkBoxOptionsCustom,
    setCheckBoxOptionsCustom,
    productDimensions,
    initialProduct = false,
    contrastDrawers,
    style,
    top,
    nail,
    size,
    setNumOfBases = () => {},
  },
  canvasRef
) => {
  const isFunction = (method) => typeof method === "function";
  const [dimensions, setDimensions] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [accessory, setAccessory] = useState(false);
  const [showPillows, setShowPillows] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [initLoad, setInitLoad] = useState(false);
  const [recline, setRecline] = useState(false);
  const [lift, setLift] = useState(false);
  const [initProductLoaded, setInitProductLoaded] = useState({});
  const [afterInit, setAfterInit] = useState(false);

  const collectionType = useMemo(() => {
    let locationSplitted = window.location.pathname.split("/");
    return window.location.search.includes("?file_name=")
      ? locationSplitted[locationSplitted.length - 1]
      : locationSplitted[locationSplitted.length - 2];
  }, []);

  useEffect(() => {
    if (
      collectionType === "senior-living-solutions" ||
      collectionType === "monogram"
    ) {
      const modelLocation = `${themeConfiguration.magento_url}media/jola_ar/`;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const ar = urlParams.get("ar");

      if (ar) {
        const link = document.createElement("a");

        const file_name = urlParams.get("file_name");

        var userAgent = navigator.userAgent.toLowerCase();

        const isAndroid = userAgent.match(/Android/i);
        const isApple = userAgent.match(/iPhone|iPad|iPod/i);

        if (isAndroid) {
          const glbLocation =
            "intent://arvr.google.com/scene-viewer/1.0?file=" +
            modelLocation +
            file_name +
            ".glb&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
          link.setAttribute("rel", "ar");
          link.appendChild(document.createElement("img"));
          link.setAttribute("href", glbLocation);
          link.click();
        } else if (isApple) {
          const usdzLocation = modelLocation + file_name + ".usdz";
          link.setAttribute("rel", "ar");
          link.appendChild(document.createElement("img"));
          link.setAttribute("href", usdzLocation);
          link.click();
        }
      }
    }
  }, [collectionType]);

  const initProductLoadedStateHandler = (name, value) =>
    setInitProductLoaded((prevState) => ({ ...prevState, [name]: value }));

  const loadingScreenHanlderAndSettingValue = (name, value) => {
    document.getElementById("loading-screen").classList.remove("fade-out");
    initProductLoadedStateHandler(name, value);
  };

  useEffect(() => {
    if (recline) player.Recline();
    if (lift) player.Lift();
    setRecline(false);
    setLift(false);
  }, [powerOption]);

  useEffect(() => {
    if (player && style && initLoad) {
      loadingScreenHanlderAndSettingValue("style", style);
      isFunction(player.setStyle) &&
        player.setStyle(
          style,
          initialProduct?.style !== style || initProductLoaded?.style
            ? true
            : false
        );
    }
  }, [style]);

  useEffect(() => {
    if (player && top && initLoad)
      loadingScreenHanlderAndSettingValue("top", top);
    isFunction(player?.setTop) &&
      player.setTop(
        top,
        initialProduct?.top !== top || initProductLoaded?.top ? true : false
      );
  }, [top]);

  useEffect(() => {
    if (player && size && initLoad)
      loadingScreenHanlderAndSettingValue("size", size);
    isFunction(player?.setSize) &&
      player.setSizes(
        size,
        initialProduct?.size !== size || initProductLoaded?.size ? true : false
      );
  }, [size]);

  useEffect(() => {
    if (player && nail && initLoad) {
      loadingScreenHanlderAndSettingValue("nail", nail);
      isFunction(player.setNail) &&
        player.setNail(
          nail,
          initialProduct?.nail !== nail || initProductLoaded?.nail
            ? true
            : false
        );
    }
  }, [nail]);
  // Use Effects
  // eslint-disable-next-line no-lone-blocks
  {
    useEffect(() => {
      if (player && initLoad && frame) {
        setLift(false);
        setRecline(false);
        loadingScreenHanlderAndSettingValue("frame", frame);
        player.LoadFrame(
          frame,
          initialProduct?.frame !== frame || initProductLoaded?.frame
            ? true
            : false
        );
      }
    }, [frame]);

    useEffect(() => {
      if (player && initLoad && arm) {
        if (
          (collectionPath !== "haven-reserve" && frame.id === "60") ||
          (frame.id === "Z" && frame.uvc)
        ) {
          player.armObject = arm;
          return;
        }

        setLift(false);
        setRecline(false);
        loadingScreenHanlderAndSettingValue("arm", arm);
        player.LoadArm(
          arm,
          initialProduct?.arm !== arm || initProductLoaded?.arm ? true : false
        );
      }
    }, [arm, player]);
    useEffect(() => {
      if (player && initLoad && back) {
        if (collectionPath !== "haven-reserve" && frame.id === "60") {
          player.backObject = back;
          return;
        }
        loadingScreenHanlderAndSettingValue("back", back);
        player.LoadBack(
          back,
          initialProduct?.back !== back || initProductLoaded?.back
            ? true
            : false
        );
      }
    }, [back, player]);
    useEffect(() => {
      if (player && initLoad && base) {
        if (
          (collectionPath !== "haven-reserve" && frame?.id === "60") ||
          frame?.id === "31" ||
          frame?.id === "32"
        ) {
          player.baseObject = base;
          return;
        }
        loadingScreenHanlderAndSettingValue("base", base);
        isFunction(player.LoadBase) &&
          player.LoadBase(
            base,
            initialProduct?.base !== base || initProductLoaded?.base
              ? true
              : false
          );
        isFunction(player.setBase) &&
          player.setBase(
            base,
            initialProduct?.base !== base || initProductLoaded?.base
              ? true
              : false
          );
      }
    }, [base, player]);

    useEffect(() => {
      // use setTimeout to wait for changing search params
      setTimeout(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setNumOfBases(searchParams.get("numOfBases"));
      }, 1000);
    }, [top, base, player, afterInit]);

    useEffect(() => {
      if (player && initLoad) {
        player.setDimensionsVisible(dimensions);
      }
    }, [dimensions]);

    useEffect(() => {
      if (player && initLoad && floor) {
        player.LoadFloor(floor.map, floor.repeat);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floor]);
    useEffect(() => {
      if (player && initLoad && mainFabric && afterInit) {
        player.LoadFinish(
          "main",
          mainFabric.map,
          mainFabric.icon,
          mainFabric.name,
          mainFabric.repeat,
          mainFabric.roughness,
          mainFabric.metalness
        );
      }
    }, [mainFabric, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (player && initLoad && backFabric) {
        player.LoadFinish(
          "outside_back",
          backFabric.map,
          backFabric.icon,
          backFabric.name,
          backFabric.repeat,
          backFabric.roughness,
          backFabric.metalness
        );
      }
    }, [backFabric, player]);

    useEffect(() => {
      if (player && initLoad && insideBack) {
        player.LoadFinish(
          "back_pillow",
          insideBack.map,
          insideBack.icon,
          insideBack.name,
          insideBack.repeat,
          insideBack.roughness,
          insideBack.metalness
        );
      }
    }, [insideBack, player]);

    useEffect(() => {
      if (player && initLoad && cushionFabric) {
        player.LoadFinish(
          "seat_cushion",
          cushionFabric.map,
          cushionFabric.icon,
          cushionFabric.name,
          cushionFabric.repeat,
          cushionFabric.roughness,
          cushionFabric.metalness
        );
      }
    }, [cushionFabric, player]);

    useEffect(() => {
      if (player && seat && initLoad) {
        loadingScreenHanlderAndSettingValue("seat", seat);
        player.LoadSeat(
          seat,
          initialProduct?.seat !== seat || initProductLoaded?.seat
            ? true
            : false
        );
      }
    }, [seat]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && seatAndBack && initLoad) {
        loadingScreenHanlderAndSettingValue("seatAndBack", seatAndBack);
        player.LoadSeat(
          seatAndBack,
          initialProduct?.seatAndBack !== seatAndBack ||
            initProductLoaded?.seatAndBack
            ? true
            : false
        );
      }
    }, [seatAndBack, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && accessoryLeft && initLoad) {
        if (accessoryLeft !== "X") {
          setAccessory(true);
        } else if (accessoryRight === "X") {
          setAccessory(false);
        }
        loadingScreenHanlderAndSettingValue("accessoryLeft", accessoryLeft);
        setTimeout(() => {
          player.LoadAccessory(
            accessoryLeft,
            "l",
            initialProduct?.accessoryLeft !== accessoryLeft ||
              initProductLoaded?.accessoryLeft
              ? true
              : false
          );
        }, 0);
      }
    }, [accessoryLeft, player]);
    useEffect(() => {
      if (player && accessoryRight && initLoad) {
        if (accessoryRight !== "X") {
          setAccessory(true);
        } else if (accessoryLeft === "X") {
          setAccessory(false);
        }
        loadingScreenHanlderAndSettingValue("accessoryRight", accessoryRight);
        setTimeout(() => {
          player.LoadAccessory(
            accessoryRight,
            "r",
            initialProduct?.accessoryRight !== accessoryRight ||
              initProductLoaded?.accessoryRight
              ? true
              : false
          );
        }, 0);
      }
    }, [accessoryRight, player]);
    useEffect(() => {
      if (player && initLoad && accessory) {
        player.SetAccessoriesVisible(accessory);
      }
    }, [accessory, player]);
    useEffect(() => {
      if (!afterInit) return;
      if (player && nailSize && initLoad) {
        typeof player.ShowFramePins === "function" &&
          player.ShowFramePins(nailSize.size);
      } else if (player) {
        let localCheckBoxOptions = JSON.parse(
          JSON.stringify(checkBoxOptionsCustom)
        );
        localCheckBoxOptions.nailhead_trim_option = false;
        setCheckBoxOptionsCustom(localCheckBoxOptions);
      }
    }, [nailSize, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && backFabric) {
        player.LoadFinish(
          "back_pillow",
          backFabric.map,
          backFabric.icon,
          backFabric.name,
          backFabric.repeat,
          backFabric.roughness,
          backFabric.metalness
        );
      }
    }, [backFabric, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && cushionFabric) {
        player.LoadFinish(
          "seat_cushion",
          cushionFabric.map,
          cushionFabric.icon,
          cushionFabric.name,
          cushionFabric.repeat,
          cushionFabric.roughness,
          cushionFabric.metalness
        );
      }
    }, [cushionFabric, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && weltFabric) {
        player.LoadFinish(
          "contrast_welt",
          weltFabric.map,
          weltFabric.icon,
          weltFabric.name,
          weltFabric.repeat,
          weltFabric.roughness,
          weltFabric.metalness
        );
      }
    }, [weltFabric, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && pillowWeltFabric) {
        player.LoadFinish(
          "pillow_contrast_welt",
          pillowWeltFabric.map,
          pillowWeltFabric.icon,
          pillowWeltFabric.name,
          pillowWeltFabric.repeat,
          pillowWeltFabric.roughness,
          pillowWeltFabric.metalness
        );
        setShowPillows(true);
      }
    }, [pillowWeltFabric, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && throwPillowFabric) {
        player.LoadFinish(
          "pillow_contrast",
          throwPillowFabric.map,
          throwPillowFabric.icon,
          throwPillowFabric.name,
          throwPillowFabric.repeat,
          throwPillowFabric.roughness,
          throwPillowFabric.metalness
        );
        setShowPillows(true);
      }
    }, [throwPillowFabric, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && nailFinish && afterInit) {
        player.LoadFinish(
          "nail_finish",
          //There was a bug here, solution taken from: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
          nailFinish.finish.map + "?not-from-cache-please",
          nailFinish.finish.icon,
          nailFinish.finish.name,
          nailFinish.finish.repeat,
          nailFinish.finish.roughness,
          nailFinish.finish.metalness
        );
        let localCheckBoxOptions = checkBoxOptionsCustom;
        if (nailSize) {
          localCheckBoxOptions.nailhead_trim_option = true;
        }
        setCheckBoxOptionsCustom(localCheckBoxOptions);
      }
    }, [nailFinish, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && nailSize && initLoad && afterInit) {
        let localCheckBoxOptions = checkBoxOptionsCustom;
        localCheckBoxOptions.nailhead_trim_option = true;
        setCheckBoxOptionsCustom(localCheckBoxOptions);
      }
    }, [nailSize, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && selectedBaseFinish) {
        player.LoadFinish(
          "base_finish",
          selectedBaseFinish.map,
          selectedBaseFinish.icon,
          selectedBaseFinish.name,
          selectedBaseFinish.repeat,
          selectedBaseFinish.roughness,
          selectedBaseFinish.metalness
        );
      }
    }, [selectedBaseFinish, player]); //
    useEffect(() => {
      if (player && initLoad && finish) {
        player.LoadFinish(finish);
      }
    }, [finish, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && striping) {
        player.LoadStriping(striping);
      }
    }, [striping, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && contrastDrawers) {
        player.LoadDrawerFinish(contrastDrawers);
      }
    }, [contrastDrawers, player]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad && selectedBaseFinish && afterInit) {
        player.LoadFinish(
          "woodMAT",
          selectedBaseFinish.map,
          selectedBaseFinish.icon,
          selectedBaseFinish.name,
          selectedBaseFinish.repeat,
          selectedBaseFinish.roughness,
          selectedBaseFinish.metalness
        );
      }
    }, [selectedBaseFinish, player, afterInit]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (player && initLoad) {
        setTimeout(() => {
          player.resize();
        }, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullscreen]);
    useEffect(() => {
      if (player && initLoad) {
        player.setCushionsVisible(showPillows);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPillows]);
    useEffect(() => {
      if (
        player &&
        initLoad &&
        afterInit &&
        !window.location.pathname.includes("build-your-own/monogram") &&
        typeof player.setNailsVisible === "function"
      ) {
        player.setNailsVisible(checkBoxOptionsCustom.nailhead_trim_option);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkBoxOptionsCustom.nailhead_trim_option, afterInit]);
    useEffect(() => {
      if (player && initLoad && powerOption) {
        setLift(false);
        setRecline(false);
        loadingScreenHanlderAndSettingValue("powerOption", powerOption);
        player.LoadPowerOption(
          powerOption,
          initialProduct?.powerOption !== powerOption ||
            initProductLoaded?.powerOption
            ? true
            : false
        );
      }
    }, [powerOption, player]);

    useEffect(() => {
      if (player && initLoad && productDimensions) {
        player.setDimensions(productDimensions);
      }
    }, [productDimensions, player]);
  }

  const CreateImageLink = (image) => {
    var a = document.createElement("a");
    a.href = image;
    a.rel = "noopener noreferrer";
    a.target = "_blank";
    a.download = sku + ".png";
    a.click();
  };

  const save3DImage = (e, backupImage) => {
    if (player) {
      let initColor = player.scene.background;
      let newColor = "#ffffff"; //change for different color
      player.scene.background.set(newColor);

      if (player.shadowPlane) player.shadowPlane.visible = false;
      if (player.plane) player.plane.visible = false;

      player.update();
      e.stopPropagation();
      new Promise((resolve) => {
        let dataUrl = undefined;
        try {
          dataUrl =
            player.container.getElementsByTagName("canvas")[0].toDataURL() ||
            undefined;
        } catch (e) {
          resolve(null);
        }
        resolve(dataUrl);
      }).then((blob) => {
        if (blob) {
          CreateImageLink(blob);
        }
      });

      player.scene.background.set(initColor);
      if (player.shadowPlane) player.shadowPlane.visible = true;
      if (player.plane) player.plane.visible = true;
    } else {
      CreateImageLink(backupImage);
    }
  };

  const playerInit = () => {
    let playerClass;
    if (collectionPath === "haven-reserve") {
      playerClass = new HavenPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.7,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 4.288),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.15,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.4,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "urban-living") {
      playerClass = new UrbanPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.7,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 4.288),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.15,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.4,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "senior-living-solutions") {
      playerClass = new SeniorPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.7,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 4.288),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.15,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.4,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "live-edge") {
      playerClass = new LiveEdgePlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.2,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 6.368),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: true,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "monogram") {
      playerClass = new MonogramPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.2,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0000579,
              mapSize: { width: 4096, height: 4096 },
              radius: 40,
              needsUpdate: true,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 6.368),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: true,
          enablePan: false,
        },
      });
    } else if (collectionPath === "benchmade-round-ottomans") {
      playerClass = new BenchmadeRoundOttomansPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.2,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 6.368),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "benchmade-benches") {
      playerClass = new BenchmadeBenchesPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.2,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 6.368),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    } else if (collectionPath === "benchmade-ottomans") {
      playerClass = new BenchmadeSquareOttomansPlayer("canvas", {
        lights: [
          {
            name: "left_light",
            intensity: 0.2,
            position: new Vector3(-8.993, 1.348, 4.378),
            type: "DirectionalLight",
          },
          {
            name: "spot_shadow_light",
            intensity: 1,
            shadow: {
              bias: -0.0002,
            },
            type: "SpotLight",
            position: new Vector3(7.965, 8.043, 6.368),
            distance: 80,
            angle: 60,
            penumbra: 0.22,
          },
          {
            name: "top_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(0.172, 0.319, 5.17),
          },
          {
            name: "back_light",
            intensity: 0.05,
            type: "DirectionalLight",
            position: new Vector3(5, 1.565, -10.693),
          },
        ],
        controls: {
          enableZoom: false,
          enablePan: false,
        },
        collectionPath,
      });
    }
    if (playerClass) {
      if (frame) playerClass.frameObject = frame;
      if (arm) playerClass.armObject = arm;
      if (seat) playerClass.seatObject = seat;
      if (back) playerClass.backObject = back;
      if (base) playerClass.baseObject = base;
      if (seatAndBack) playerClass.seatObject = seatAndBack;
      if (style) playerClass.style = style;
      if (top) playerClass.top = top;
      if (nail) playerClass.nail = nail;
      if (size) playerClass.size = size;
      if (base && collectionPath === "live-edge")
        playerClass.baseSku = base?.sku;
      if (top && collectionPath === "live-edge") playerClass.topSku = top?.sku;

      document.getElementById("loading-screen").classList.remove("fade-out");
      playerClass.Init().then(() => setAfterInit(true));
      setPlayer(playerClass);
      window.player = playerClass;
      setInitLoad(true);
      return () => {
        setInitLoad(false);
      };
    }
  };

  useEffect(() => playerInit(), []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <RotationBar />
      <div
        ref={canvasRef}
        id="canvas"
        style={{
          minHeight: isMobile ? "33.5rem" : playerHeight,
          maxHeight: isMobile ? "33.5rem" : playerHeight,
          height: isMobile ? "33.5rem" : playerHeight,
          minWidth: playerWidth,
          maxWidth: playerWidth,
          width: playerWidth,
          position: "relative",
          display: "block !important",
          zIndex: 1,
        }}
      >
        <MDBCol
          style={{
            position: "absolute",
            right: "-4.5rem",
            top: "6rem",
            transform: "rotateZ(-90deg)",
            width: "auto",
            zIndex: "10",
          }}
        >
          <ZoomControls player={player} isMobile={isMobile} />
        </MDBCol>
        {/* {!isMobile && (
          <MDBBox
            style={{
              position: "absolute",
              top: "2.5%",
              right: "0",
              width: "50%",
            }}
            className="d-flex align-items-center justify-content-end"
          >
            <MDBCol
              size="1"
              onClick={() => {
                toggleFullscreen();
              }}
              className="cursor-pointer"
            >
              <MDBRow>
                <MDBCol size="5" className="flex-center cursor-pointer">
                  <Icon icon={isFullscreen ? "closeDefault" : "plus"} />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBBox>
        )} */}
        <MDBBox className={`d-flex align-items-center justify-content-between`}>
          <MDBBox
            className={`align-items-center d-flex  ${
              isMobile && "flex-row-reverse justify-content-between w-100"
            }`}
            style={{
              position: "absolute",
              bottom: (isMobile && "auto") || "2.5%",
              top: (!isMobile && "auto") || "2.5%",
              left: "0",
              width: "100%",
            }}
          >
            <MDBCol className={`${isMobile && "d-flex justify-content-start"}`}>
              <Icon icon="camera360" width="40" height="40" />
            </MDBCol>
          </MDBBox>
          {collectionType === "monogram" ? (
            <Button
              className="btn-secondary ar-button"
              onClick={async () => {
                if (player) {
                  setQrCodeUrl(player.viewInRoom());
                  setIsOpen(true);
                }
              }}
            >
              View in Your Room
            </Button>
          ) : null}
          <MDBRow
            className={`mx-lg-0 d-flex ${isMobile && "justify-content-center"}`}
            style={{
              position: "absolute",
              bottom: "5.5%",
              right: "0",
              left:
                (isMobile &&
                  ((frame?.name !== "Chair" &&
                    frame?.name !== "Ottoman" &&
                    frame?.name.indexOf("Swivel") === -1 &&
                    "20%") ||
                    "50%")) ||
                "auto",
              transform:
                (isMobile &&
                  ((frame?.name !== "Chair" &&
                    frame?.name !== "Ottoman" &&
                    frame?.name.indexOf("Swivel") === -1 &&
                    "translate(-10%, 0)") ||
                    "translate(-45%, 0)")) ||
                "initial",
            }}
          >
            {frame?.name !== "Chair" &&
              frame?.name !== "Ottoman" &&
              frame?.name.indexOf("Swivel") === -1 &&
              collectionPath !== "senior-living-solutions" &&
              collectionPath !== "monogram" && (
                <MDBCol className="cursor-pointer">
                  <MDBRow>
                    <MDBCol
                      onClick={() => {
                        setShowPillows(!showPillows);
                      }}
                    >
                      <Icon
                        icon={showPillows ? "pillowActive" : "pillowInactive"}
                        title={showPillows ? "Remove Pillow" : "Throw Pillow"}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              )}
            {/* {collectionPath !== "monogram" && (
              <MDBCol className="cursor-pointer pl-lg-0">
                <MDBRow>
                  <MDBCol
                    onClick={() => {
                      let localCheckBoxOptions = JSON.parse(
                        JSON.stringify(checkBoxOptionsCustom)
                      );
                      localCheckBoxOptions.nailhead_trim_option = !checkBoxOptionsCustom.nailhead_trim_option;
                      setCheckBoxOptionsCustom(localCheckBoxOptions);
                    }}
                  >
                    <div
                      className={`show-nails ${
                        checkBoxOptionsCustom.nailhead_trim_option && "selected"
                      }`}
                    >
                      <Icon
                        icon={
                          checkBoxOptionsCustom.nailhead_trim_option
                            ? "nailheadsSelected"
                            : "nailheads"
                        }
                        title={
                          checkBoxOptionsCustom.nailhead_trim_option
                            ? "Remove Nail Heads"
                            : "Nail Heads"
                        }
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            )} */}
            <MDBCol
              onClick={() => {
                setDimensions(!dimensions);
              }}
              className={`cursor-pointer px-lg-0 ${
                isMobile &&
                "d-flex justify-content-center justify-content-lg-start"
              }`}
            >
              <MDBRow>
                <MDBCol className="flex-center cursor-pointer">
                  <Icon
                    icon={
                      dimensions ? "dimensionsActive" : "dimensionsInactive"
                    }
                    title={dimensions ? "Hide Dimensions" : "Dimensions"}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            {!isMobile ? (
              <div
                onClick={() => {
                  toggleFullscreen();
                }}
                className="cursor-pointer plus-sign"
              >
                <Icon icon={`enlargeIcon`} />
              </div>
            ) : null}
            {/* Change active and inactive icon for Accessory and remove parent div with style */}
            {/*{(collectionPath === "senior-living-solutions" && (*/}
            {/*  <MDBCol*/}
            {/*    onClick={() => {*/}
            {/*      setAccessory(!accessory);*/}
            {/*    }}*/}
            {/*    className={`cursor-pointer pr-lg-0 ${*/}
            {/*      isMobile &&*/}
            {/*      "d-flex justify-content-center justify-content-lg-start"*/}
            {/*    }`}*/}
            {/*  >*/}
            {/*    <MDBRow>*/}
            {/*      <MDBCol className="flex-center cursor-pointer">*/}
            {/*        <div*/}
            {/*          className={`${*/}
            {/*            accessory ? "bg-default" : "bg-gray"*/}
            {/*          } d-flex align-items-center justify-content-center`}*/}
            {/*          style={{*/}
            {/*            width: "40px",*/}
            {/*            height: "40px",*/}
            {/*            borderRadius: "50%",*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <Icon*/}
            {/*            icon={accessory ? "accessoryActive" : "accessory"}*/}
            {/*            title={accessory ? "Hide Accessory" : "Accessory"}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </MDBCol>*/}
            {/*    </MDBRow>*/}
            {/*  </MDBCol>*/}
            {/*)) || <></>}*/}
            {/* <ModalConsumer value={{ useWrapper: false }}>
              {({ showModal, hideModal }) => {
                return (
                  <>
                    <MDBCol
                      className={`cursor-pointer`}
                      onClick={(e) => {
                        e.preventDefault();
                        showModal(() => {
                          return (
                            <Sidebar
                              current={floor}
                              options={floorOptions}
                              setOption={setFloor}
                              hideModal={hideModal}
                            />
                          );
                        });
                      }}
                    >
                      <MDBRow>
                        <MDBCol className="flex-center">
                          <Icon icon={"flooringActive"} title={"Flooring"} />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </>
                );
              }}
            </ModalConsumer> */}
          </MDBRow>
        </MDBBox>
        {frame?.recliner && (
          <div className={"animation-container d-flex"}>
            <div
              className={`animation-button mr-3 d-flex align-items-center ${
                recline && "animation-completed"
              }`}
              onClick={() => {
                if (powerOption?.id === "SR") {
                  player.Recline(0.7, 1.4);
                } else {
                  player.Recline();
                }
                setLift(false);
                setRecline(!recline);
              }}
            >
              {!recline ? (
                <Icon icon={"plusSmall"} title={"Plus"} />
              ) : (
                <Icon icon={"minusSmall"} title={"Minus"} />
              )}
              Recline
            </div>
            {powerOption?.id === "PL" && (
              <div
                className={`animation-button d-flex align-items-center ${
                  lift && "animation-completed"
                }`}
                onClick={() => {
                  player.Lift();
                  setRecline(false);
                  setLift(!lift);
                }}
              >
                {!lift ? (
                  <Icon icon={"plusSmall"} title={"Plus"} />
                ) : (
                  <Icon icon={"minusSmall"} title={"Minus"} />
                )}
                Lift
              </div>
            )}
          </div>
        )}
        <LoadingScreen />
      </div>
      <ARModal
        isOpen={isOpen}
        setQrCodeUrl={setQrCodeUrl}
        qrCodeUrl={qrCodeUrl}
        setIsOpen={setIsOpen}
        collectionType={collectionType}
        isMobile={isMobile}
      />
    </>
  );
};
const ZoomControls = React.memo(
  ({ player, isMobile }) => {
    let [zoomState, setZoomState] = React.useState({ ...zoomOptions });
    let zoomLevels = [];
    for (let i = zoomState.min; i <= zoomState.max; i++) {
      if (zoomLevels) {
        zoomLevels.push(i);
      } else {
        zoomLevels = [i];
      }
    }
    return (
      <div
        id="playerControls"
        className="d-flex align-items-center justify-content-between"
        style={{
          height: "3rem",
          width: "12rem",
          // transform: (isMobile && "rotate(-90deg)") || "none",
          // position: (isMobile && "absolute") || "static",
          // top: (isMobile && "3rem") || "auto",
        }}
      >
        <Icon
          onClick={() => {
            setZoomState(player.updateZoom(zoomState.current, -1));
          }}
          icon="zoomMinus"
          style={{
            height: "2rem",
            width: "2rem",
            cursor: "pointer",
            userSelect: "none",
            transform: "rotate(90deg)",
          }}
        />
        {zoomLevels.map((i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setZoomState(player.updateZoom(i));
              }}
              style={{
                background:
                  i <= zoomState.current
                    ? "rgba(108, 128, 139,1)"
                    : "rgba(108, 128, 139,0.3)",
                height: "1.5rem",
                width: "0.3rem",
                cursor: "pointer",
              }}
            />
          );
        })}
        <Icon
          onClick={() => {
            setZoomState(player.updateZoom(zoomState.current, 1));
          }}
          icon="zoomPlus"
          style={{
            height: "2rem",
            width: "2rem",
            cursor: "pointer",
            userSelect: "none",
          }}
        />
      </div>
    );
  },
  (prevPlayer, nextPlayer) => {
    return (
      typeof prevPlayer?.player !== "undefined" &&
      typeof prevPlayer?.player?.controls !== "undefined" &&
      typeof prevPlayer?.player?.controls.minDistance !== "undefined" &&
      prevPlayer?.player?.controls?.minDistance !== 0 &&
      typeof nextPlayer?.player !== "undefined" &&
      typeof nextPlayer?.player?.controls !== "undefined" &&
      typeof nextPlayer?.player?.controls.minDistance !== "undefined" &&
      nextPlayer?.player?.controls?.minDistance !== 0
    ); //=== nextPlayer.controls.minDistance;
  }
);

const RotationBar = () => {
  const [currentRotationPercent, setCRP] = React.useState(
    (window?.player?.cameraAngle / 360) * 100 || 50
  );
  React.useEffect(() => {
    setInterval(() => {
      setCRP((window?.player?.cameraAngle / 360) * 100 || 50);
    }, 1000 / 30);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        // height: "0.2rem",
        // background: `linear-gradient(90deg, rgba(136, 136, 136,1) 0%, rgba(136, 136, 136,1) ${currentRotationPercent}%, rgba(174,174,174,0.5) ${
        //   currentRotationPercent + 1
        // }%, rgba(174,174,174,0.500437675070028) 100%)`,
      }}
    />
  );
};

const LoadingScreen = () => {
  return (
    <MDBRow
      id="loading-screen"
      className="flex-center flex-column"
      style={{
        backgroundColor: "#f7f7f7",
        paddingLeft: "3rem",
        paddingRight: "3rem",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "1.5rem",
        fontSize: "2rem",
      }}
    >
      <Loader className={"h-auto"} />
      <MDBTypography tag="h3" className="mt-3">
        Loading 3D Model...
      </MDBTypography>
      <Icon
        icon="jola-dark"
        style={{
          position: "absolute",
          bottom: "10%",
          right: "10%",
          opacity: "0.8",
          height: "9rem",
        }}
      />
    </MDBRow>
  );
};

export const Player = React.forwardRef(_Player);
