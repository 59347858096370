import React from "react";
import SeoHelmet from "app/layout/seoHelmet";
import { MDBContainer, MDBRow } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import useTagboxHelper from "app/layout/Tagbox/useTagboxHelper";

const Residential = () => {
  const { tag } = useTagboxHelper();

  return (
    <>
      <SeoHelmet title={"Residential"} url={"/residential"} />
      <MDBContainer className={"no-header-offset home-container p-0"} fluid>
        <MDBContainer className="m-0 hero-banner" fluid>
          <MDBRow className="justify-content-center">
            {/* <CmsBlock id="residential_hero" /> */}
            <CmsBlock id="crypton_hero_banner" />
          </MDBRow>
        </MDBContainer>
        <CmsBlock id="residential_multi_block" />
        <CmsBlock id="residential_to_the_trade" />
        <CmsBlock id="residential_2024_lookbook" />
        <CmsBlock id="residential_coley_chairs" />
        <CmsBlock id="residential_pillows" />
        <CmsBlock id="residential_lets_get_social" />
        <CmsBlock id="residential_social_img_right" />
        {tag}
      </MDBContainer>
    </>
  );
};

export default Residential;
