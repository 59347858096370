import React from "react";
import Component from "./dynamicGraphQlWrapper";
import DynamicGraphQlWrapperQueryFetchContainer from "./dynamicGraphQlWrapper-query-fetch-container";
import PropTypes from "prop-types";

const DynamicGraphQlWrapperQueryContainer = (props) => {
  if (typeof props.data !== "undefined" && props.data[props.variables.url]) {
    const children = React.Children.map(props.children, (child) => {
      return React.cloneElement(child, { data: props.data });
    });
    return <Component {...props}>{children}</Component>;
  }
  return <DynamicGraphQlWrapperQueryFetchContainer {...props} />;
};

DynamicGraphQlWrapperQueryContainer.propTypes = {
  data: PropTypes.object,
  variables: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default DynamicGraphQlWrapperQueryContainer;
